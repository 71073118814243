<template>
  <div class="main">
    <div class="cerceve">
      <h4 style="color: #ff9900">Upcoming Event ...</h4>
      <img class="kitapStand" src="../assets/sofia_abla.svg" alt="Sofia" />
      <div class="yazi" style="color: #ff9900">
        Seats are by reservation only. To make a reservation, follow the instuctions in
        the link to the right:
        <a
          href="https://hizmethousephotos.s3.amazonaws.com/reservation.pdf"
          download
          target="_blank"
          ><i class="fa fa-download" style="font-size: 24px; color: rgb(0, 0, 0)"></i
        ></a>
      </div>
    </div>
    <div>
      <Quote />
    </div>
    <div class="body-text hizmethouse">
      <h4 style="color: #ff9900">What We Do</h4>
      <ul>
        <li aria-level="1">
          Organize book clubs of books by Fethullah Gulen Hocaefendi and Bediuzzaman Said
          Nursi.
        </li>
        <li aria-level="1">
          Hold traditional “sohbet” sessions centered around books by FG and BSN.
        </li>
        <li aria-level="1">
          Host roundtable discussions of concepts stated in the books.
        </li>
        <li aria-level="1">
          Organize conferences and seminars about FG, BSN, their lives, teachings given by
          local and national speakers.
        </li>
        <li aria-level="1">
          Clarify and explain terminology that does not translate directly into English.
        </li>
        <li aria-level="1">
          Organize trips to visit people and institutions inspired by Hizmet movement.
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="row">
        <div class="col home">
          <div class="imagetext">
            <img
              class="container__image"
              src="../assets/hocaefendi.jpeg"
              alt="hocaefendi"
              style="width: 15em"
              height="250 px"
            />
            <div class="container__text">
              <h4 style="color: #ff9900">Who is Fethullah Gulen?</h4>
              <p>
                Fethullah Gülen is a Muslim scholar, thinker, author, poet, opinion
                leader, educational activist, and preacher emeritus. He is regarded as the
                initiator and inspirer of the worldwide social movement of human values
                known as the Hizmet (Service) Movement or the Gülen Movement. Focused on
                education where secular curriculum are taught by teachers who aspire to
                “represent” high values of humanity, this social phenomenon defeats easy
                categorization.
              </p>
              <a class="readmore" href="/who-is-fethullah-gulen">Read More</a>
            </div>
          </div>
        </div>
        <div class="col home">
          <div class="imagetext">
            <img
              class="container__image"
              src="../assets/ustad_3.jpeg"
              alt="ustadhz"
              style="width: 15em"
              height="250 px"
            />
            <div class="container__text">
              <h4 style="color: #ff9900">Who is Said Nursi?</h4>
              <p>
                Said Nursi (1876-1960) was an Islamic modernist who founded the
                nondenominational Nur Movement, which advocated for a reinterpretation of
                Islam according to the needs of a modern society, a legacy of attempts by
                the Young Ottomans to reconcile Islam with constitutionalism. He was
                strongly opposed to positivism, and believed that change would only come
                through the cultivation of a new mindset, not through transforming
                institutions themselves.
              </p>
              <a class="readmore" href="/who-is-said-nursi">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quote from "../components/Quote";
export default {
  name: "Home",
  components: {
    Quote,
  },
};
</script>

<style>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";

.main {
  padding-bottom: 25px;
  width: 87.5%;
  margin: auto;
}

.readmore {
  text-align: justify;
  margin: auto;
  width: 50%;
  border: 10px solid orange;
  text-decoration: none;
  color: #000;
  background-color: orange;
  align-items: center;
}

.readmore:hover {
  color: #fff;
}

.hizmethouse > h4 {
  text-align: center;
}

.hizmethouse a {
  margin-left: 45%;
}

.row a {
  margin-left: 40%;
}

.hizmethouse > p {
  text-align: justify;
  font-size: 15px;
  color: #000000;
}

.home > h4 {
  padding-top: 50px;
  margin-bottom: 28px;
  font-size: 28px;
  letter-spacing: -1px;
  text-transform: none;
  font-family: "Prata", serif;
  color: #01090c;
}

.body-text {
  top: 2000px;
  color: rgb(100, 100, 11);
}

.container__image {
  margin-top: 15px;
  margin-left: 0px;
  float: left;
}
.container__text {
  padding-top: 20px;
  margin-top: 20px;
  display: inline;
}

.container__text > h4 {
  text-align: left;
}

.container {
  padding-top: 25px;
}

.cerceve > h4 {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.cerceve > div {
  text-align: center;
}

.cerceve {
  width: 60%;
  height: 600px;
  margin: auto;
  padding: 1px 25px;
}

.kitapStand {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
