<template>
  <div style="height: 300pxpx" class="kc-google-maps main">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.3364583307457!2d-80.66186658439462!3d35.32246555717073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88541a436fde11f3%3A0x42e4f6eceff32d32!2s113%20Morris%20Dr%2C%20Harrisburg%2C%20NC%2028075!5e0!3m2!1sen!2sus!4v1642273854886!5m2!1sen!2sus"
      width="100%"
      height="300"
      frameborder="0"
      style="border: 0"
      allowfullscreen=""
    ></iframe>

    <div class="container">
      <div class="row">
        <div class="contactus col-sm">
          <h3>Send us a message</h3>
          <form @submit="submitMessage">
            <div class="mb-3">
              <label class="form-label">Your Name (required)</label>
              <input
                type="text"
                class="form-control"
                id="fullName"
                name="fullName"
                value
                size="100"
                v-model="name"
              />
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label"
                >Your email (required)</label
              >
              <input
                type="email"
                class="form-control"
                id="email"
                name="_replyto"
                value
                size="100"
                aria-describedby="emailHelp"
                v-model="email"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Subject</label>
              <input
                type="text"
                class="form-control"
                id="subject"
                name="subject"
                value
                size="100"
                v-model="subject"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Your Message</label>
              <textarea
                class="form-control"
                rows="3"
                name="mesage"
                v-model="message"
              ></textarea>
            </div>

            <button type="submit" class="btn btn-primary">
              Send
              <i class="fa fa-paper-plane"></i>
            </button>
          </form>
        </div>
        <div class="col-sm">
          <div class="container">
            <div class="row">
              <div class="kc_wrapper kc-col-inner-container col-sm">
                <div class="kc-elm kc-css-746215 kc-title-wrap contactus">
                  <h3 class="kc_title"><span>Address</span></h3>
                </div>

                <div class="kc-elm kc-css-287316 kc_text_block">
                  <p><strong>Address: </strong> 113 Morris DR, Harrisburg, NC 28075</p>
                  <p><strong>Phone: </strong> +1 (980) 866 9970</p>
                  <p>
                    <strong>Email:</strong>
                    <a href="mailto:info@hizmethouse.org" style="margin-left: 3%"
                      >info@hizmehouse.org</a
                    >
                  </p>
                </div>
              </div>
              <div class="kc_wrapper kc-col-inner-container col-sm">
                <div class="kc-elm kc-css-746215 kc-title-wrap contactus">
                  <h3 class="kc_title"><span>Office Hours</span></h3>
                </div>

                <div class="kc-elm kc-css-287316 kc_text_block">
                  <p><strong>Monday to Friday: </strong> 9am to 5.30pm</p>
                  <p>
                    <strong>Weekends: </strong>
                    Closed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "Contactus",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
  },
  components: {},
  methods: {
    successToast() {
      createToast(
        {
          title: "Email Received.",
          description: "We will get in touch with you shortly.",
        },
        {
          type: "success",
          position: "top-center",
          timeout: 5000,
          transition: "zoom",
        }
      );
    },
    failureToast() {
      createToast(
        {
          title: "Email not received.",
          description:
            "We apologize for the inconvenience, please send a direct email to info@hizmethouse.org.",
        },
        {
          type: "warning",
          position: "top-center",
          transition: "zoom",
          timeout: 5000,
        }
      );
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.subject = "";
      this.message = "";
    },
    async submitMessage(e) {
      e.preventDefault();

      const message = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };

      const response = await fetch("https://formspree.io/f/xdobekqb", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(message),
      });

      this.resetForm();
      if (response.status === 200) {
        this.successToast();
      } else if (response.status != 200) {
        this.failureToast();
      }
    },
  },
};
</script>

<style>
.main {
  width: 87.5%;
  margin: auto;
}

.container{
  margin-bottom: 50px;
}

.contactus > h3 {
  padding-top: 0px;
  margin-bottom: 28px;
  font-size: 28px;
  letter-spacing: -1px;
  text-transform: none;
  font-family: "Prata", serif;
  color: #009bde;
}
</style>
