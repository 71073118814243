<template>
  <div class="visioncontent">
    <h4>
      <span style="color: #ff9900"> Values </span>
    </h4>
    <p>
      The Hizmet movement is a civil society movement committed to the ideals of living
      together in peace and serving humanity, and it works within the framework of social
      responsibility and philanthropy. The movement is centered on the concept of
      altruistic volunteerism and prioritizes education, dialogue and humanitarian aid in
      its activities. It is a peaceful movement that embraces religious, social and
      cultural diversity and is inspired by Islamic and universal humanitarian values.
    </p>
    <p>
      “This work on values is commendable and has a promise for the future. I have the
      belief and hope that the activities of Hizmet volunteers, devoted to these core
      values, on the trajectory of helping others live, with the high goal of God’s
      pleasure, will contribute significantly to the formation of a more livable world of
      peace and tranquility. May God bless those who contributed to this work.” –
      Fethullah Gulen
    </p>
    <p>
      <span style="color: #ff9900"><b data-fusion-font="true">Our Mission</b></span>
    </p>
    <p>
      To study and promote the teachings of Fethullah Gulen Hocaefendi and Bediuzzaman
      Said Nursi.
    </p>
    <p>
      <span style="color: #ff9900"><b data-fusion-font="true">Our Vision</b></span>
    </p>
    <p>
      A society enriched by the teachings of Fethullah Gulen Hocaefendi and Bediuzzaman
      Said Nursi.
    </p>
    <p>
      <span style="color: #ff9900"><b data-fusion-font="true">Our Goals</b></span>
    </p>
    <ul>
      <li aria-level="1">
        To inform the public on teachings of Fethullah Gulen Hocaefendi and Bediuzzaman
        Said Nursi.
      </li>
      <li aria-level="1">To promote understanding of principles of Hizmet.</li>
      <li aria-level="1">
        To study Islam in the context of views of Fethullah Gulen Hocaefendi and
        Bediuzzaman Said Nursi.
      </li>
    </ul>
    <p>
      <span style="color: #ff9900"><b data-fusion-font="true">Objectives</b></span>
    </p>
    <ul>
      <li aria-level="1">
        Organize book clubs of books by Fethullah Gulen Hocaefendi and Bediuzzaman Said
        Nursi ;
      </li>
      <li aria-level="1">
        Hold traditional “sohbet” sessions centered around books by FG and BSN ;
      </li>
      <li aria-level="1">
        Host roundtable discussions of concepts stated in the books ;
      </li>
      <li aria-level="1">
        Organize conferences and seminars about FG, BSN, their lives, teachings given by
        local and national speakers ;
      </li>
      <li aria-level="1">
        Clarify and explain terminology that does not translate directly into English ;
      </li>
      <li aria-level="1">
        Organize trips to visit people and institutions inspired by Hizmet movement ;
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Visionmission",
  components: {},
};
</script>

<style>
.visioncontent {
  padding: 10px 90px 50px 90px;
  width: 87.5%;
  margin: auto;
}

li {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.714;
  color: #666;
  font-weight: 300;
}

h4 {
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-transform: uppercase;
  margin-top: 23px;
  margin-bottom: 11.5px;
  font-size: 24px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.714;
  color: #666;
  font-weight: 300;
  box-sizing: border-box;
  margin: 0 0 11.5px;
}
</style>
