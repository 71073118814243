<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top navbar-custom">
    <div class="container-fluid">
      <router-link class="logo" to="/"
        ><img class="logoimg" src="../assets/cropped.svg" alt="logo" style="width: 9em"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link active text-nowrap"
              aria-current="page"
              href="/who-is-fethullah-gulen"
              >Fethullah Gulen</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link active text-nowrap"
              aria-current="page"
              href="/who-is-said-nursi"
              >Said Nursi</a
            >
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link text-nowrap dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About Us
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="/vision-mission">Vision & Mission</a>
              </li>
              <!-- <li>
                <a class="dropdown-item" href="/album">Gulen & Nursi Album</a>
              </li> -->
              <li>
                <a class="dropdown-item" href="/iflc"
                  >The International Festival of Language & Culture</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/love-is-a-verb">Love Is A Verb</a>
              </li>
              <li>
                <a class="dropdown-item" href="/iftar2022">Iftar 2022</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link text-nowrap" href="/contactus">Contact us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style>
li > a {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}
/* .fixed-top {
  position: relative !important;
} */
.custom-sub {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-sub > li:first-child {
  margin-top: 15px;
}
.custom-sub > li:last-child {
  margin-bottom: 15px;
}
.nav-item a {
  color: #ff9900 !important;
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
button.navbar-toggler{
  background-color: #ff9900;
}

.logoimg {
  width:4em; 
  height:6em;
}

.navbar .navbar-collapse {
  margin-left: auto !important;
}
.navbar-nav {
  float: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.navbar-nav > li {
  display: inline-block;
  float: none;
}
.navbar-custom {
  height: 100px;
  align-content: center;
  background-color: #000000;
}
/* #2d8894; */
/* #014aac; */
@media (min-width: 992px) {
  .navbar li {
    margin-left: 5em;
    margin-right: 5em;
  }
}
@media screen and (max-width: 992px) {
  #header .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
  }
}
</style>