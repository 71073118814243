<template>
  <div class="loveisaverb">
    <div class="container__text">
      <div>
        <h4>
          <span style="color: #ff9900">
            This is a documentary about Hizmet (Gulen Movement) and M. Fethullah Gülen
            Hocaefendi.
          </span>
        </h4>
          <iframe
            class="loveVerbVideo"
            width="650"
            height="400"
            src="https://www.youtube.com/embed/VoBznWXHJGg"
            allowfullscreen
          >
          </iframe>
        <p>
          <span style="color: #ff9900"><b data-fusion-font="true">The Story</b></span>
        </p>

        <p>
          Love Is A Verb is an examination of a social movement of Sufi inspired Sunni
          Muslims that began in Turkey in the l960s and now reaches across the globe. The
          group is called Hizmet, the Turkish word for service or The Gulen Movement after
          its inspiration, leader and beloved teacher Fethullah Gulen, a man that Time
          Magazine named as one of the most influential leaders in the world in 2013.
        </p>

        <p>
          Through a co-operation with the people inspired by Fethullah Gulen, we had an
          unprecedented access to the ideas and actions of the movement around the world.
          We met teachers who crawled through a tunnel to open a school in Sarajevo during
          the war and the students whose lives they changed. We met a Sufi conductor whose
          orchestra is composed of children whose parents were once at war. We got a
          glimpse of the interfaith work the movement provides in Turkey, including a
          visit to Rumi’s exquisite shrine. We went to their schools in Turkey, in
          Somalia, in Iraq. We meet a Kurdish teacher in Iraq who credits everything she
          is to her former Turkish teachers who stayed during the bombings, and a Kurdish
          woman who had also gone to one of the Turkish schools in Iraq and is now working
          as an engineer to bring water to the dessert. Finally, in Somalia we follow two
          Turkish doctors putting their lives at risk in a place where other relief
          organizations have deemed too dangerous, a place where they sleep under armed
          guard.
        </p>
        <p>
          <span style="color: #ff9900"
            ><b data-fusion-font="true">Director’s message</b></span
          >
        </p>
        <p>
          In the decade after 9/11, I was vaguely aware that it was not a good time to be
          a Muslim in America; nor was it a good time to be an American in the Middle
          East. But for me – like for most Americans –this awareness morphed into specific
          and non-specific fears about Islam and the extreme interpretations of it. Then
          in 2010, I met a group of people from the Niagara Foundation who invited me on
          an interfaith trip to Turkey. I am not religious; but I am curious and jumped at
          the chance to see Turkey and to have an adventure with a culture that was
          foreign to me.
        </p>
        <p>
          I also looked at the trip as an opportunity to discover new stories to film. I
          never dreamed that it would change my worldview. After the trip I decided that
          others’ worldview had to be changed as well. After a lot of reading, several
          more trips and a lot of scouting and interviewing, we began our journey, which
          eventually had us filming in six countries. This film introduced us to the work
          of people whose motives I would have doubted had I not witnessed their selfless
          commitment and bravery with my own eyes. These members of the global Hizmet
          movement, a loosely-affiliated group of people inspired by the work of Fethullah
          Gulen are dedicated to addressing social cohesion through education, dialogue,
          and anti-poverty work. We met people who went into war zones to educate but also
          to mitigate the hatred and desire for revenge that lasts long after the last
          body is buried. And we met others who repeatedly went to some of the most
          dangerous places on earth to bring medical relief. I met a woman who answered
          one of my questions with, “we have to love” as a simple declarative statement
          and a philosophical imperative.
        </p>
        <p>
          At first I wasn’t sure what to make of these people and wondered why they would
          put so much on the line without complaint or question. I wondered whether it was
          a con that I couldn’t see through because of our cultural differences. Much
          later I was granted an unprecedented interview with the man who inspired this
          movement. I was touched by Fethullah Gulen’s genuineness (even to the point of a
          slight discomfort with me and our entire crew filming him) and surprised by his
          obvious shyness at being with a strange western woman. What you will see in our
          film is a tiny piece of what we saw and experienced. I may write a book about
          all of the things that didn’t happen on camera, about the importance of dialogue
          both among and between groups. After three years of working together, the first
          person who took me to Turkey, Hakan Berberoglu has become my family. I am still
          in awe of his continual modesty and his calmness under very difficult
          circumstances. I am also in awe of the movement at large;
        </p>
        <p>
          people whose customs and belief systems are so different from my own who have
          enriched my understanding of the world and proven that Love is a Verb.
        </p>
        <p>
          Terry Spencer Hesser<br />
          Writer, Director & Producer
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoveIsVerb",
};
</script>

<style>
.loveisaverb {
  padding: 10px 90px 50px 90px;
  width: 87.5%;
  margin: auto;
}

.loveVerbVideo, object, embed {
    max-width: 100%;
    max-height: 100%;
    float: right;
}

@media (max-width: 991px) {
  .loveVerbVideo {
    height: 50%;
  }
}

h4 {
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-transform: uppercase;
  margin-top: 23px;
  margin-bottom: 11.5px;
  font-size: 24px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.714;
  color: #666;
  font-weight: 300;
  box-sizing: border-box;
  margin: 0 0 11.5px;
}
</style>
