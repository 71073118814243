<template>
    <footer class="footer-dark">
      <div class="container">
        <div class="row">
          <div class="col-md-3 item text">
            <p class="footerp">113 Morris DR, Harrisburg, NC 28075&nbsp;</p>
            <a class="footera" href="mailto:info@hizmethouse.org" style="margin-left: 1%"
              >INFO@HIZMETHOUSE.ORG
            </a>
            <br />
            <p class="footerp">+1 (980) 866 9970</p>
          </div>
          <div class="col item social">
            <a href="https://www.facebook.com/hizmethousorg" target="_blank"
              ><i class="fa fa-facebook"></i></a
            ><a href="https://twitter.com/hizmethouseorg" target="_blank"
              ><i class="fa fa-twitter"></i></a
            ><a
              href="https://www.youtube.com/channel/UC7gVgrQR_w1c-ryAzV8hKkw"
              target="_blank"
              ><i class="fa fa-youtube"></i></a
            ><a href="https://www.instagram.com/hizmethouseorg/" target="_blank"
              ><i class="fa fa-instagram"></i
            ></a>
          </div>
          <p class="copyright">Copyright © Hizmet House. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.container {
  margin-bottom: 0px !important;
}

.footer-dark {
  padding: 0px 0;
  color: whitesmoke;
  background-color: #282d32;
  height: 185px;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  opacity: 0.3;
  font-size: 16px;
  margin-bottom: 0;
  color: whitesmoke;
}

.footerp {
  color: whitesmoke;
}

.footera  {
  text-decoration: none;
}
</style>
