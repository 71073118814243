import { createRouter, createWebHistory } from 'vue-router'
import Contactus from "../views/Contactus";
import Visionmission from "../views/Visionmission";
import FethullahGulen from "../views/FethullahGulen";
import SaidNursi from "../views/SaidNursi";
import Home from "../views/Home";
import Iflc from "../views/Iflc";
import LoveIsVerb from "../views/LoveIsVerb"
import Iftar2022 from "../views/Iftar2022"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: Contactus
  },
  {
    path: '/vision-mission',
    name: 'Vision & Mission',
    component: Visionmission
  },
  {
    path: '/who-is-fethullah-gulen',
    name: 'FethullahGulen',
    component: FethullahGulen
  },
  {
    path: '/who-is-said-nursi',
    name: 'SaidNursi',
    component: SaidNursi
  },
   {
    path: '/iflc',
    name: 'Iflc',
    component: Iflc
  },
  {
    path: '/love-is-a-verb',
    name: 'LoveIsVerb',
    component: LoveIsVerb
  },
  {
    path: '/iftar2022',
    name: 'Iftar2022',
    component: Iftar2022
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
