<template>
  <div class="aboutiflc">
    <h4>
      <span style="color: #ff9900">
        The International Festival of Language & Culture
      </span>
    </h4>
    <p>
      The International Festival of Language & Culture (IFLC) formerly known as
      Uluslararası Türkçe Olimpiyatları (International Turkish Olympiad) is a premier
      organization for promoting world languages and cultures. It’s dedicated to
      cultivating artistic self-expression among youth and creating a platform from them
      to share their cultural heritage with peers around the world.
    </p>
    <p>
      The International Festival of Language & Culture (IFLC) is an annual celebration of
      language diversity that showcases talent from across the globe. The IFLC first
      started in 2003 with only 17 countries participating.As of today, over 2,000
      performers and production members from more than 160 nations have taken part in our
      events. Onstage, young students offer songs, dances and inspiring storytelling
      around themes of compassion, respect and mutual understanding. Offstage, in every
      city we visit, our performers enjoy opportunities to engage with members of the
      local community, explore unique educational experiences and build lasting
      friendships. It’s the IFLC way of raising hopes for world peace.
    </p>
    <p>
      For more information visit:
      <a
        style="text-decoration: none; color: #666"
        href="https://intflc.org/"
        target="_blank"
        >intflc.org</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutIflc",
};
</script>

<style>
.aboutiflc {
  padding: 10px 90px 0px 90px;
  width: 87.5%;
  margin: auto;
}

h4 {
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-transform: uppercase;
  margin-top: 23px;
  margin-bottom: 11.5px;
  font-size: 24px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.714;
  color: #666;
  font-weight: 300;
  box-sizing: border-box;
  margin: 0 0 11.5px;
}
</style>
