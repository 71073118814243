<template>
  <div class="allQuotes">
    <div class="giant-quotes-quote">
      <blockquote>
        <p>
          {{ quotes[0].quote }}
        </p>
        <cite>{{ quotes[0].author }}</cite>
      </blockquote>
    </div>
  </div>
</template>

<script>
import quotes from "../assets/quotes.json";
export default {
  name: "Quote",
  data() {
    return {
      quotes: quotes,
    };
  },
  mounted() {
    window.setInterval(() => {
      this.pollQuote();
    }, 120000);
  },
  methods: {
    pollQuote() {
      const firstQuote = this.quotes.shift();
      this.quotes = this.quotes.concat(firstQuote);
    },
  },
};
</script>

<style>
.allQuotes {
  padding-top: 100px;
  padding-bottom: 25px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .allQuotes {
    padding-top: 250px;
  }
}

.giant-quotes-quote {
  display: inline-block;
  font-family: sans-serif;
  margin: 1em;
}
.giant-quotes-quote blockquote {
  display: inline-block;
  margin: 0;
  padding: 1em;
  position: relative;
}
.giant-quotes-quote blockquote:before {
  color: #f90;
  content: "\201C";
  font-size: 4em;
  left: 0;
  line-height: 0.75em;
  position: absolute;
  top: 0;
  transform: scale(2, 8);
  transform-origin: top;
}
.giant-quotes-quote blockquote:after {
  color: #f90;
  content: "\201D";
  font-size: 4em;
  line-height: 0.75em;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(2, 8);
  transform-origin: top;
}
.giant-quotes-quote blockquote > * {
  padding: 0 2em;
}
.giant-quotes-quote blockquote cite {
  color: #c60;
  display: block;
  font-style: normal;
  text-align: right;
  text-transform: uppercase;
}
</style>
