<template>
  <div class="videolar">
    <div class="container">
      <div class="row frameSpace">
        <div class="col-md-4">
          IFLC 2012 Istanbul, Turkey
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/uIQqWI1pS6k"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2017 Melbourne, Australia
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/v8rb-CW3wQQ"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2018 New York, United States
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/d32xJttpOWw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div class="row frameSpace">
        <div class="col-md-4">
          IFLC 2016 Pasay, Philippines
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/Fv6CQukY-HI"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2016 Dusseldorf, Germany
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/ePuRyNNkRgU"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2021 New York, United States
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/lwLyqMGau94"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div class="row frameSpace">
        <div class="col-md-4">
          IFLC 2020 Bucharest, Romania
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/FdbUqiXGpJo"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2019 Tononto, Canada
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/V14onj_cDZQ"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4">
          IFLC 2015 New York, United States
          <div class="ratio ratio-4x3">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/YltvXZJJVqw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Videos",
};
</script>

<style>
.videolar {
  width: 87.5%;
  margin: auto;
}

.frameSpace {
  padding-bottom: 25px;
}

.col-lg-4 {
  text-align: center;
}
</style>
