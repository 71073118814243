<template>
  <div class="main">
    <div class="main_second">
      <div class="container">
        <img
          class="container__image"
          src="../assets/ustad.jpeg"
          alt="ustadhz"
          width="200px"
          height="250px"
        />
        <div class="container__text">
          <h4 style="color: #ff9900">Who is Bediuzzaman Said Nursi?</h4>
          <p>
            Said Nursi was an Islamic modernist who founded the nondenominational Nur
            Movement, which advocated for a reinterpretation of Islam according to the
            needs of a modern society, a legacy of attempts by the Young Ottomans to
            reconcile Islam with constitutionalism. He was strongly opposed to positivism,
            and believed that change would only come through the cultivation of a new
            mindset, not through transforming institutions themselves.
          </p>
          <p>
            Ustad Nursi was born to a peasant family in the village of Nurs in the Bitlis
            province of the Kurdish regions of the Ottoman Empire. While he was ethnically
            Kurdish, his father Sofi Mirza (d. 1920) descended from the grandson of the
            Messenger of God (peace and blessings be upon him) Ḥasan (may God be pleased
            with him, d. 669) through ʿAbd al-Qādir al-Gīlānī (d. 1166). His mother Nure
            Hanım (d. during WWI) descended from the other grandson of the Messenger of
            God (peace and blessings be upon him) Ḥusayn (may God be pleased with him, d.
            680). Therefore, Ustad Nursi connected to the prophetic lineage both as a
            sharīf, i.e. a descendent of Ḥasan, and a sayyid, i.e. a descendent of Ḥusayn
            (may God be pleased with both of them). His mother and father were illiterate
            and poor, but they were known for their piety and fear of God, and they
            ensured that all of their children would study.
          </p>
          <p>
            Ustad Nursi left his home at the age of nine to study in the local madrasahs
            of what was then known as Kurdistan. Receiving proper education proved to be
            challenging for him at the beginning though. It was difficult to find a
            teacher who could satisfy the quick mind, photographic memory, and piercing
            intellect of this evidently extraordinary child. He eventually reached that
            fertile ground at the madrasah of Muhammed Celalî Hazret (1851-1914) in Doğu
            Beyazıt. Here, he fast-tracked through the entire madrasah curriculum over a
            winter in 1892-93, memorized many of the books that his peers studied, and
            received his first ijāzah, or certificate of knowledge and teaching,
            approximately at the age of 15 from Muhammed Celalî Hazret. Later, he would
            receive a second ijāzah from Shaykh Fethullah el-Siirdî (d. 1899). In both of
            these ijāzahs, Ustad Nursi’s chain of knowledge extends to ʿAli (may God be
            pleased with him, d. 661), the fourth caliph, the son-in-law of the Messenger
            of God (peace and blessings be upon him), and the father of Ḥasan and Ḥusayn
            (may God be pleased with both of them) through Imam al-Ghazālī (d. 1111).
          </p>
          <p>
            During his early years of study and travel among the madrasahs of Kurdistan,
            young Nursi also attracted the attention of prominent Sufi masters, and
            reportedly, he received special blessings and spiritual insight from some of
            them, such as the famous Naqshbandi Shaykh ʿAbd al-Raḥmān al-Taghī (d. 1887).
            However, as it should become clear upon studying Ustad Nursi’s lifelong career
            and the Risale-i Nur, his mission and teachings encompassed the essence of all
            major Sufi orders (ṭarīqahs) and were not limited to one.
          </p>
          <p>
            Although Ustad Nursi never stopped learning, already by the time he received
            his ijāzahs, his knowledge as well as his ability to apply that knowledge to
            difficult scholarly questions or practical matters were unmatched. This
            extraordinary aptitude earned him the alias “Bediuzzaman,” meaning the “marvel
            of the age” among the scholarly circles of the Ottoman Empire's eastern lands.
            Later, when he traveled to Istanbul, this qualification would be acknowledged
            by world-renowned scholars of the time too. To give an indication of Ustad
            Nursi’s scholarly accomplishment, he had memorized over 90 major books that
            were taught in the Ottoman madrasahs along with the Qur’an, thousands of
            prophetic traditions, and a major and voluminous Arabic dictionary up to the
            letter sīn. He was never asked a question in scholarly examinations but he
            gave a sound and convincing answer. His knowledge was both kasbī, i.e. a
            reward for his determined effort to learn, and wahbī, i.e. bestowed to him as
            a special gift from God. The inspired aspect of the nature of his knowledge
            was evident in his ability to evaluate and resolve difficult questions, and it
            was also confirmed in reliable dreams. In one of those dreams, which he had
            had in1891, shortly before going to Muhammed Celalî Hazret’s madrasah in Doğu
            Beyazıt, the Messenger of God (peace and blessings be upon him) had told Nursi
            that he would be given knowledge on condition that he would abstain from
            asking questions to members of the Prophet’s (peace and blessings be upon him)
            community of followers (ummah) in way of testing their knowledge. In the
            second dream, which Ustad Nursi had had at the beginning of World War One, he
            was instructed by what he calls "an important person" to proclaim the
            miraculousness of the Qur’an
          </p>
          <p>
            After teaching and preaching in various Kurdish provinces for a few years, in
            1897, Ustad Nursi accepted an invitation from the governor of the city of Van
            and moved there. He stayed in this less provincial city for ten years and
            taught his own students. Meanwhile, he also explored modern scientific and
            political literature in the governor's evidently large library. Because this
            library perished in a fire, we do not know exactly what Ustad Nursi had
            studied there. However, it appears that he had mastered advanced works in
            several disciplines of knowledge from physiology and geography to philosophy
            and algebra to the extent that he would be tested by the experts of those
            disciplines at the governor’s manor and consistently come out undefeated.
          </p>
          <p>
            In 1907, Ustad Nursi traveled to Istanbul, the capital of the Ottoman Empire,
            primarily to seek financial and political support to open a madrasah that
            would teach religious and positive sciences together simultaneously in
            Turkish, Kurdish, and Arabic in the empire’s eastern lands. This was a
            tumultuous period for the Ottoman Empire though. A few months after his
            arrival in Istanbul, Ustad Nursi found himself in the thick of revolutionary
            developments that transformed the sultanate into a constitutional monarchy. He
            put his knowledge and oratory skills in the service of expanding freedoms in
            the empire in this period, though importantly, he would insist that those
            freedoms should be exercised within the bounds of the Shariah. Yet the initial
            euphoria of the revolution soon left its place to a chaotic struggle for
            power. Ustad Nursi returned to Van in 1912 and started the construction of his
            madrasah. But the advent of World War One prevented the completion of this
            project. Ustad Nursi moved on to command a regiment of voluntary soldiers
            against invading Russian armies.
          </p>
          <p>
            Meanwhile, he also began writing a Qur’anic commentary. This commentary, which
            he would dictate to one of his students as he simultaneously commanded his
            soldiers on horseback, comprises the first chapter of the Qur’an al-Fātiḥa and
            the first 33 verses of the second chapter al-Baqara. It also constitutes one
            of the books of Ustad Nursi’s magnum opus Risale-i Nur Külliyatı, or the
            Compendium of the Epistles of Light. Unfortunately, this blessed effort to
            write a commentary on the Qur’an had to come to an abrupt end when Ustad Nursi
            was wounded in a battle, captured, and taken as a prisoner of war to the city
            of Kostroma in northern Russia.
          </p>
          <p>
            Approximately two lonely years of introspection in this northern city marked
            the beginning of a spiritual crisis that would last more than a decade for
            Ustad Nursi. He escaped from imprisonment during Russia's chaotic state in the
            wake of the Bolshevik Revolution at the end of 1917. He traveled to Istanbul
            through Europe. Upon his arrival, he was welcomed as a war hero, and in
            recognition of his scholarly aptitude, he was appointed as a member of the
            empire’s highest council of Islamic scholarship: Dârü’l-Hikmeti’l-İslâmiye.
            Yet the realization of the ephemeral nature of this fame and the worldly
            blessings that accompanied it actually deepened Ustad Nursi’s inner crisis. He
            ultimately came out of it with the spiritual assistance of ʿAbd al-Qādir
            al-Gīlānī and Imam Rabbānī Aḥmad Sirhindī (d. 1624), as he read their Futūḥ
            al-Ghayb (or possibly Fatḥ al-Rabbānī) and Maktūbāt respectively. This
            spiritual assistance guided Ustad Nursi away from the weakness of his
            commanding soul (nafs) for fame and its subtle tendency to seek solutions to
            worldly problems through worldly arrangements. With advice from Imam Rabbānī,
            he resolved to concentrate all of his intellectual and spiritual effort on
            understanding the Qur’an and proclaiming its message.
          </p>
          <p>
            Nonetheless, the world would not leave Ustad Nursi alone yet. Shortly after
            his arrival in Istanbul, the Ottoman Empire accepted defeat in World War One
            and collapsed. Then, Allied forces occupied Istanbul. A resistance movement
            emerged in Anatolia around what came to be known as the Grand National
            Assembly, which convened in the city of Ankara in 1920. Upon the invitation of
            this Assembly, Nursi traveled to Ankara with the intention to support the
            resistance. However, he soon noticed that leaders of the resistance movement
            actually wanted to do away with religion in the new political situation that
            they were fighting to establish. Ustad Nursi perceived in this development
            some of the signs of the end of times, and with guidance from some prophetic
            traditions that shed light on such periods in history, he withdrew to a cave
            in the city of Van. Here, as he emerged from his long spiritual crisis with a
            deeper state of realization (taḥqīq), he wanted to avoid all social
            interaction, the fame that accompanied it, and power politics as a source of
            much injustice. Yet, a Kurdish rebellion in Southeast Turkey in 1925, two
            years after the foundation of the republic, disrupted Ustad Nursi's plans. He
            had not supported this rebellion. He had even prevented some clans in Van from
            joining it. But the state authorities feared his reputation and charismatic
            influence as a scholar of Islam among the region’s Kurdish population. Thus,
            they exiled him first to Isparta, a city in southwest Anatolia, and then to a
            nearby mountain village called Barla.
          </p>
          <p>
            The authorities’ choice of Barla as Nursi’s place of exile was due to its
            inaccessibility. At the time, one had to climb a mountain by car through
            serpentine roads, cross a lake by boat, and then climb further before reaching
            Barla. Yet this remote place of exile served as the incubator where God put
            Ustad Nursi in the service of the Qur'an and religion in his new state of
            realization. Ustad Nursi refers to himself before this realization as the “Old
            Said” and after it as the “New Said.” While Old Said was a scholar and
            activist with a sharp intellect and daring character, New Said was a
            compassionate spiritual teacher who relied on his intimate understanding of
            the Qur’an to establish a broad path to God that comprised the essence of all
            established paths and from which all believers from uninitiated peasants to
            advanced scholars could benefit.
          </p>
          <p>
            The villagers of Barla recognized the gift of knowledge and light that Ustad
            Nursi brought to their doorsteps. Slowly, they began to gather around him. The
            Risale-i Nur initially emerged from Ustad Nursi’s teachings to these villagers
            in the form of brief treatises. However, we should note that it comprised much
            more than lessons for an uninitiated audience. A careful comparison of Ustad
            Nursi’s previous works, especially from the early 1920s, and the Risale-i Nur
            reveals that following years of deep reflection upon the Qur’an and the
            observed universe as two forms of revelation, Ustad Nursi had already reached
            the level of realization and illumination that characterizes the Risale-i Nur.
            He had recorded the inspired wisdoms emerging from this reflection in the form
            of brief notes for himself and published some of them especially in the early
            1920s. Therefore, his teachings to Barla’s villagers and later to a larger
            circle of students were not mere inspirations of the moment. They contained
            inspired knowledge which he received due to his decades of intellectual and
            spiritual preparation. In the persons of his immediate readers, this knowledge
            addressed the questions and needs of an entire age characterized by the
            ailments of positivism, consumerist materialism, and even militant
            anti-religious indoctrination.
          </p>
          <p>
            It was not easy for an exiled scholar of Islam to publish his works in early
            republican Turkey. The villagers of Barla hand-copied his treatises and
            secretly passed them on to other readers in a gradually growing network of
            readers and copiers. This went on for almost nine years. The number of people
            who secretly but devotedly read and copied Ustad Nursi’s treatises in Barla
            and in other nearby villages reached thousands. In 1935, however, the
            government clamped down on this network. They arrested Nursi along with
            several of his closer students and sent them to Eskişehir in central Anatolia
            to be tried. The court failed to produce a sentence, but Ustad Nursi was once
            again exiled, this time to Kastamonu, again, in north central Anatolia. The
            developments of Barla repeated in Kastamonu. Students gathered around Ustad
            Nursi secretly. They read, hand-copied, and spread his works. Ustad Nursi kept
            in touch with his former students with secretly sent and often hand-delivered
            letters. Then he was arrested, tried, and exiled again, this time to Emirdağ,
            a small town in western Anatolia. Students gathered around him there too, and
            he kept on writing and teaching despite a number of other court trials,
            detentions, and even being poisoned several times.
          </p>
          <p>
            While the treatises of the Risale-i Nur offer a treasure of knowledge and
            spiritual light, Ustad Nursi and his students’ patient struggle and unwavering
            perseverance before injustices, slanders, deprivations, imprisonment,
            psychological pressure, and physical torture in this period provide the
            foundations of a model of principled action. This model should be considered a
            central component of Ustad Nursi’s overall teaching too. One learns about it
            from his correspondence with his students, which are compiled into four
            volumes in the compendium of the Risale-i Nur, as well as from the example of
            the students who personally learned from Ustad Nursi.
          </p>
          <p>
            Some of those students even had the fortune to live with Ustad Nursi in the
            same house during the last seven to eight years of his blessed life. In 1950,
            when Ustad Nursi was 72 years old, Turkey’s ruling party changed in the
            country’s first democratic election. The new regime relaxed its grip on
            religion relatively. Close government surveillance on Ustad Nursi continued in
            this period too, but he was allowed to rent a house in Isparta, settle there
            with a few of his students, and publish his books in print. He stopped writing
            new treatises for the most part and focused on training his students and
            spreading the Risale-i Nur. His readership grew to hundreds of thousands
            within a few years. At the end of 1959, he asked his students to take him on a
            journey through various cities where he met some of his other students. Then,
            in March 1960, when he was seriously ill in the month of Ramadan, he asked to
            be taken to the city of Urfa in southeast Anatolia. This was a blessed city
            that hosts the tombs and spiritual stations of several prophets. There, he
            passed away shortly after his arrival at the age of 82 on the Night of Power,
            or the 27th night of the month of Ramadan in the Islamic calendar.
          </p>
          <p>
            Ustad Nursi was initially buried in Urfa, in the Dergah Mosque which is
            believed to have been constructed on the site where Prophet Ibrāhīm (peace be
            upon him) was born. However, a coup d’etat took place in Turkey shortly after
            Ustad Nursi's death. Fearing that his tomb would serve as a place of gathering
            for his large following, the secularist military junta unearthed his body in
            July 1960 and buried it in an undisclosed location, possibly in the mountains
            of Isparta. Interestingly, it was Ustad Nursi’s will that his tomb would
            remain hidden and people would pray for his soul without visiting his tomb.
            The junta had unknowingly fulfilled his will.
          </p>
          <p>
            Ustad Nursi's will for his tomb to remain hidden reflects his state of
            spiritual realization in which he perceived and acknowledged all the good that
            appeared in his hands to be from God alone. As a result, he would either not
            accept praise at all or, if he feared breaking the heart of the person who
            praised him, he would accept it in the name of the Risale-i Nur in relation to
            its inspired derivation from the Qur’an. He said that he did not like himself
            or those who liked him for his self. He feared affectation and detested shows
            of respect to his person. As a result, the larger his following grew, the more
            humble he became and chose a life of seclusion. He would rarely accept
            visitors and often turn away even those who traveled from distant locations to
            see him. He would reject anyone who came to him expecting a blessing through
            him. He would tell them that they should read the Risale-i Nur instead. But he
            would accept those who came to him to share his burden in serving the Qur’an
            and the religion by spreading the Risale-i Nur.
          </p>
          <p>
            He was extremely modest, but in proclaiming the truth and preserving the
            dignity of the knowledge he carried, he was daring and fearless. When asked to
            stand up before the tsar’s uncle during his imprisonment in Russia, he refused
            on the grounds that this would not become the dignity of his scholarship.
            Instead, he accepted to be put in front of the execution squad. The tsar’s
            uncle pardoned him in the last minute upon recognizing the sincerity in Ustad
            Nursi's unhesitant preparedness to die in the way of preserving the dignity of
            his knowledge.
          </p>
          <p>
            All his life, Ustad Nursi aspired to be for God and God alone. He abstained
            from all that that could attract or tie him to the world, hurt the sincerity
            of his intentions, or diminish the purity of his exertion in the service of
            the Qur’an. He was the recipient of many divine gifts and special blessings
            that broke the norm and confirmed the truth of his cause, but he neither asked
            for nor expected them, hoping to receive his reward in the Hereafter alone. He
            lived in poverty, wore old and patched clothing, and ate little to survive,
            but he would not accept even gifts let alone charity. He slept little too and
            spent most of his nights in a state of worship and supplication.
          </p>
          <p>
            He was extremely compassionate, crying upon the sight of falling leaves,
            forbidding his students from killing mosquitoes, or sharing his meager prison
            provision with mice. It was this compassion that underlined his determination
            to preserve believers’ faith at a time when state policies and changing ways
            of life pushed them away from religion and into hellfire. He proclaimed that
            one who attains true faith can defy the entire creation, and he defied the
            dictatorial powers of a state that adamantly pursued the eradication of
            religion. He remained principled in his defiance though. He believed that ends
            did not justify the means since God creates both the means and the ends and
            what behooves His slaves is to serve Him within the bounds He permits. Thus,
            he relied on God with absolute certainty, remained steadfast on his cause,
            expected and asked for success from God alone, and God blessed us with the
            Risale-i Nur through him.
          </p>
          <p>May God have mercy on his soul.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaidNursi",
};
</script>

<style>
.container__image {
  margin: 43px;
  float: left;
}
.container__text {
  display: inline;
}

.container__text > h4 {
  text-align: left;
}
</style>
