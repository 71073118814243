<template>
  <div class="iftar">
    <h4>
      <span style="color: #ff9900">Ramadan and Softening Hearts</span>
    </h4>
    <p>
      The month of Ramadan appears on our horizons with its charming beauties with
      fasting, the Prayers of Tarawih, the fast-breaking dinners, and the pre-dawn meals.
      Ramadan brings about a heavenly atmosphere of its own. Even at times when different
      tensions follow one another, violence and aggression become excessive, contradiction
      is seen as virtue, and ice-cold winds blow between the masses, Ramadan makes its
      influence by reconditioning souls, fostering sound hearts, feelings, and thoughts,
      along with abating every kind of hardness and harshness. Muslims already show
      serious respect toward this month, when peace and gentleness perceptibly prevail.
      For this reason, in spite of different adversities, if we give our willpower its due
      and manage to open our heart to this very special and distinguished segment of time
      with heartfelt trust in its blessings, and thus become oriented to it with sincere
      belief, awe and respect, then it will cuddle us and shower us with blessings. Anger,
      violence, and rages will cease, and an atmosphere of peace and reconciliation will
      prevail.
      <br />
      - Fethullah Gulen
    </p>
    <div class="containeralbom">
      <input type="radio" id="i1" name="images" checked />
      <input type="radio" id="i2" name="images" />
      <input type="radio" id="i3" name="images" />
      <input type="radio" id="i4" name="images" />
      <input type="radio" id="i5" name="images" />
      <input type="radio" id="i6" name="images" />
      <input type="radio" id="i7" name="images" />
      <input type="radio" id="i8" name="images" />
      <input type="radio" id="i9" name="images" />
      <input type="radio" id="i10" name="images" />

      <div class="slide_img" id="one">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3648.jpg" />

        <label class="prev" for="i10"><span>&#x2039;</span></label>
        <label class="next" for="i2"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="two">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3651.jpg" />

        <label class="prev" for="i1"><span>&#x2039;</span></label>
        <label class="next" for="i3"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="three">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3653.jpg" />

        <label class="prev" for="i2"><span>&#x2039;</span></label>
        <label class="next" for="i4"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="four">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3655.jpg" />

        <label class="prev" for="i3"><span>&#x2039;</span></label>
        <label class="next" for="i5"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="five">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3657.jpg" />

        <label class="prev" for="i4"><span>&#x2039;</span></label>
        <label class="next" for="i6"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="six">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3658.jpg" />

        <label class="prev" for="i5"><span>&#x2039;</span></label>
        <label class="next" for="i7"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="seven">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG_6218.jpeg" />

        <label class="prev" for="i6"><span>&#x2039;</span></label>
        <label class="next" for="i8"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="eight">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3668.jpg" />

        <label class="prev" for="i7"><span>&#x2039;</span></label>
        <label class="next" for="i9"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="nine">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3663.jpg" />

        <label class="prev" for="i8"><span>&#x2039;</span></label>
        <label class="next" for="i10"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="ten">
        <img src="https://hizmethousephotos.s3.amazonaws.com/iftar-2022/IMG-3676.jpg" />

        <label class="prev" for="i9"><span>&#x2039;</span></label>
        <label class="next" for="i1"><span>&#x203a;</span></label>
      </div>

      <div id="nav_slide">
        <label for="i1" class="dots" id="dot1"></label>
        <label for="i2" class="dots" id="dot2"></label>
        <label for="i3" class="dots" id="dot3"></label>
        <label for="i4" class="dots" id="dot4"></label>
        <label for="i5" class="dots" id="dot5"></label>
        <label for="i6" class="dots" id="dot6"></label>
        <label for="i7" class="dots" id="dot7"></label>
        <label for="i8" class="dots" id="dot8"></label>
        <label for="i9" class="dots" id="dot9"></label>
        <label for="i10" class="dots" id="dot10"></label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Iftar2022",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Just+Another+Hand");

.iftar {
  padding-bottom: 25px;
  width: 87.5%;
  margin: auto;
}

body,
html {
  width: 85%;
  height: 85%;
  margin: 0;
  font-family: "Just Another Hand", cursive;
  overflow-x: hidden; /* optional */
}

#i1,
#i2,
#i3,
#i4,
#i5,
#i6,
#i7,
#i8,
#i9,
#i10 {
  display: none;
}

.containeralbom {
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  width: 70%;
  height: 0;
  padding-bottom: 38%;
  user-select: none;
  background-color: #1c1c1c;
  border: solid 10px #616161;
  border-radius: 10px;
}

.containeralbom .slide_img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.containeralbom .slide_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prev,
.next {
  width: 12%;
  height: inherit;
  position: absolute;
  top: 0;
  background-color: rgba(88, 88, 88, 0.2);
  color: rgba(244, 244, 244, 0.9);
  z-index: 99;
  transition: 0.45s;
  cursor: pointer;
  text-align: center;
}

.next {
  right: 0;
}
.prev {
  left: 0;
}

label span {
  position: absolute;
  font-size: 100pt;
  top: 50%;
  transform: translateY(-50%);
}

.prev:hover,
.next:hover {
  transition: 0.3s;
  background-color: rgba(88, 88, 89, 0.8);
  color: #ffffff;
}

.containeralbom #nav_slide {
  width: 100%;
  bottom: 12%;
  height: 11px;
  position: absolute;
  text-align: center;
  z-index: 99;
  cursor: default;
}

#nav_slide .dots {
  top: -5px;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  position: relative;
  border-radius: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.4s;
}

#nav_slide .dots:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.25s;
}

.slide_img {
  z-index: -1;
}

#i1:checked ~ #one,
#i2:checked ~ #two,
#i3:checked ~ #three,
#i4:checked ~ #four,
#i5:checked ~ #five,
#i6:checked ~ #six,
#i7:checked ~ #seven,
#i8:checked ~ #eight,
#i9:checked ~ #nine,
#i10:checked ~ #ten {
  z-index: 9;
  animation: scroll 1s ease-in-out;
}

#i1:checked ~ #nav_slide #dot1,
#i2:checked ~ #nav_slide #dot2,
#i3:checked ~ #nav_slide #dot3,
#i4:checked ~ #nav_slide #dot4,
#i5:checked ~ #nav_slide #dot5,
#i6:checked ~ #nav_slide #dot6,
#i7:checked ~ #nav_slide #dot7,
#i8:checked ~ #nav_slide #dot8,
#i9:checked ~ #nav_slide #dot9,
#i10:checked ~ #nav_slide #dot10 {
  background-color: rgba(255, 255, 255, 0.9);
}

@keyframes scroll {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

h4 {
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-transform: uppercase;
  margin-top: 23px;
  margin-bottom: 11.5px;
  font-size: 24px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.714;
  color: #666;
  font-weight: 300;
  box-sizing: border-box;
  margin: 0 0 11.5px;
}
</style>
