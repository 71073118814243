<template>
  <div class="main">
    <div class="main_second">
      <div class="container">
        <img
          class="container__image"
          src="../assets/hocaefendi.jpeg"
          alt="hocaefendi"
          style="width: 15em"
          height="250px"
        />
        <div class="container__text">
          <h4 style="color: #ff9900">Who is Fethullah Gulen Hocaefendi?</h4>
          <p>
            Fethullah Gülen is a Turkish Muslim scholar, thinker, author, poet, opinion
            leader, educational activist, and preacher emeritus. He is regarded as the
            initiator and inspirer of the worldwide social movement of human values known
            as the Hizmet (Service) Movement or the Gülen Movement. Focused on education
            where secular curriculum are taught by teachers who aspire to “represent” high
            values of humanity, this social phenomenon defeats easy categorization.
          </p>
          <p>
            Volunteer participants in the movement, consisting of students, academicians,
            business owners, professionals, public officials, white-collar and blue-collar
            workers, farmers, men and women, young and old, contribute to multiple ways of
            service, which crystallize in tutoring centers, schools, colleges, hospitals,
            a major relief organization, publishing houses, and media institutions, both
            in Turkey and in more than a hundred countries of the world.
          </p>
          <p>
            Gulen’s discourse cherishes and his life exemplifies values like empathic
            acceptance, altruistic service of one’s community and humanity in general,
            complementary roles of the intellect and the heart, sincerity, holistic view
            of the human, deepening faith and love of the creation. He is noted for his
            pro-democracy, pro-science, pro-dialogue and non-violence stances in critical
            junctures of the history of his society. In May 2008, Fethullah Gulen was
            listed among the top hundred public intellectuals in the world by Foreign
            Policy magazine .
          </p>
          <p>
            Despite the high regard millions hold for him, Gulen considers himself only
            one of the volunteers of the civil society movement he helped originate and
            denounces any attribution of leadership. He spends most of his time reading,
            writing, editing, worshiping, and receiving medical care. Sharing the
            suffering of humans in every corner of the world, he has always been known for
            his deep respect for and connection to all creation. “Living to let others
            live” (“yasatmak icin yasamak” in Turkish) is the core principle of his
            understanding of service. His promotion of dialogue, empathic acceptance, and
            harmonious coexistence can best be reflected in a comparison with that of
            Rumi, the 13th Century Anatolian spiritual poet and one of Gulen’s sources of
            inspiration.
          </p>
          <p>
            Fethullah Gülen was born into a humble family in Erzurum, Turkey, in 1941 ,
            and was raised in a spiritually enriching environment. He attended a public
            elementary school for three years but could not continue due to the
            appointment of his father to a village where there was no public school. He
            later obtained his diploma by self-studying and passing a comprehensive
            examination. His religious education consisted of studies in classical Islamic
            sciences such as Qur’anic recitation and memorization, exegesis (tafseer),
            Arabic language, Prophetic Tradition (hadith) as well as the spiritual
            tradition of Islam (tasawwuf), which he studied under renown scholars and
            spiritual masters around his hometown such as Muhammed Lutfi Efendi of Alvar.
          </p>
          <p>
            During the 1950s Fethullah Gülen completed his religious education and
            training under various prominent scholars and Sufi masters leading to the
            traditional Islamic ijaza (license to teach). This education was provided
            almost entirely within an informal system, tacitly ignored and unsupported by
            the state and running parallel to its education system. At the same time,
            Fethullah Gülen pursued and completed his secondary level secular education
            through external exams. In the late fifties, he came across compilations of
            the scholarly work Risale-i Nur (Epistles of Light) by Said Nursi but never
            met its famous author.
          </p>
          <p>
            After passing an exam administered by the Turkish State’s Directorate of
            Religious Affairs (Diyanet Isleri Baskanligi) in 1958, he was awarded a state
            preacher license and began to preach and teach in Edirne, a province on the
            European part of the country. In this period of his youth, he had the
            opportunity to deepen his knowledge in the Islamic tradition, informally study
            social and natural sciences, and examine the classics of both Eastern and
            Western philosophy and literature. Among the historic figures who had the most
            impact on his intellectual life we can mention Abu Hanifa, Ghazali, Imam
            Rabbani, Rumi, Yunus Emre, and Nursi. It was his broad-ranged reading attitude
            that equipped him for his well-known comprehensive interpretations.
          </p>
          <p>
            Throughout his career he maintained his personal life style of devout
            asceticism while mixing with people and remaining on good terms with the civic
            and military authorities he encountered in the course of that service. He
            witnessed how the youth were being attracted into extremist, radical
            ideologies, and strove through his preaching to draw them away from that.
            Using his own money he would buy and distribute published materials to counter
            an aggressively militant atheism and communism. He saw the erosion of
            traditional moral values among the youth and the educated segment of Turkish
            society feeding into criminality, political and societal conflict. These
            experiences were formative influences on his intellectual and community
            leadership and reinforced his faith in the meaning and value of human beings
            and life.
          </p>
          <p>
            In 1961, Fethullah Gülen began his compulsory military service in Ankara and
            was later transferred to the Mediterranean coastal city of Iskenderun. In
            Iskenderun, his commanding officer assigned to him the duty of lecturing
            soldiers on faith and morality, and, recognizing Fethullah Gülen’s
            intellectual ability, gave him many Western classics to read. Fethullah Gülen
            attributes his comprehensive exposure to the western philosophical thought to
            the encouragement of this commander. Throughout his military service Fethullah
            Gülen maintained his ascetic lifestyle as before.
          </p>
          <p>
            In 1963, following military service, Fethullah Gülen gave a series of lectures
            in Erzurum on Rumi. He also co-founded an anti-communist association there, in
            which he gave evening talks on moral issues. In 1964, he was assigned a new
            post in Edirne, where he became very influential among the educated youth and
            ordinary people. The militantly laicist authorities were displeased by his
            having such influence and wanted him dismissed. Before they could do so,
            Fethullah Gülen obliged them by having himself assigned to another city,
            Kirklareli, in 1965. There, after working hours, he organized evening lectures
            and talks. In this phase of his career, just as before, he took no active part
            in party politics and taught only about moral values in personal and
            collective affairs.
          </p>
          <p>
            In 1966, Yasar Tunagur, who had known Fethullah Gülen from earlier in his
            career, became deputy head of the country’s Directorate of Religious Affairs
            and, on assuming his position in Ankara, he assigned Fethullah Gülen to the
            post that he himself had just vacated in Izmir. On March 11, Gülen was
            transferred to the Izmir region, where he held managerial responsibility for a
            mosque, a student study and boarding-hall, and for preaching in the Aegean
            region. He continued to live ascetically. For almost five years he lived in a
            small hut near the Kestanepazari Hall and took no wages for his services. It
            was during these years that Fethullah Gülen’s ideas on education and service
            to the community began to take definite form and mature. From 1969 he set up
            meetings in coffee-houses, lecturing all around the provinces and in the
            villages of the region. He also organized summer camps for middle and high
            school students.
          </p>
          <p>
            In Izmir, the largest province of the west coast of Turkey Fethullah Gülen’s
            outstanding discourse began to crystallize and his audience to expand. He
            traveled from city to city to give sermons in mosques, speeches at gatherings
            in various places including theatres and coffee houses. Speaking on essential
            subjects ranging from peace and social justice to philosophical naturalism,
            his primary aim always remained as urging the younger generation to harmonize
            intellectual enlightenment with spirituality anchored in the faith tradition,
            and to serve fellow humans altruistically.
          </p>
          <p id="reg">
            Gulen’s discourse, which had been easily distinguished by its depth of
            knowledge, logic, sensitivity, proper referencing and stellar eloquence,
            attracted the attention of the learned citizens including academic community
            and college students, as well as common people all around the country. His
            speeches were recorded on tape, distributed even in villages, and zealously
            embraced. As he frankly asserts, he simply thought to cultivate this public
            credit, “though he never deserved it,” by channeling good intentions and
            devotional energy towards a positive end.
          </p>
          <p>
            Fethullah Gulen describes this initially national and subsequently universal
            ideal as “gathering around high human values” by means of education and
            dialogue. Regarding this ideal, Fethullah Gülen has always named his function
            as an “advisor” or “motivator” at most. His audience in Izmir initially served
            as a seed to form a community of like-minded citizens from all walks of life
            and later expanded to citizens from very different backgrounds, including
            non-Muslims who share the humanistic dimension of Gulen’s vision if not its
            Islamic roots.
          </p>
          <p>
            In 1970, as a result of the March 12 coup, a number of prominent Muslims in
            the region, who had supported Kestanepazari Hall and associated activities for
            the region’s youth, were arrested. On May 1, Fethullah Gülen too was arrested
            and held for six months without charge until his release on November 9. Later,
            all the others arrested were also released, also without charge. When asked to
            explain these arrests, the authorities said that they had arrested so many
            leftists that they felt they needed to arrest some prominent Muslims in order
            to avoid being accused of unfairness. Interestingly, they released Fethullah
            Gülen on the condition that he gave no more public lectures.
          </p>
          <p>
            In 1971, Fethullah Gülen left his post and Kestanepazari Hall but retained his
            status as a state-authorized preacher. He began setting up more student study
            and boarding-halls in the Aegean region: the funding for these came from local
            people. It is at this point that a particular group of about one hundred
            people began to be visible as a service group, that is, a group gathered
            around Fethullah Gülen’s understanding of service to the community and
            positive action.
          </p>
          <p>
            Between 1972 and 1975, Fethullah Gülen held posts as a preacher in several
            cities in the Aegean and Marmara regions, where he continued to preach and to
            teach the ideas about education and the service ethic he had developed. He
            continued setting up hostels for high school and university students. At this
            time educational opportunities were still scarce for ordinary Anatolian
            people, and most student accommodation in the major cities, controlled or
            infiltrated by extreme leftists and rightists, seethed in a hyper-politicized
            atmosphere. Parents in provincial towns whose children had passed entrance
            examinations for universities or city high schools were caught in a dilemma –
            to surrender their children’s care to the ideologues or to deny them further
            education and keep them at home. The hostels set up by Fethullah Gülen and his
            companions offered parents the chance to send their children to the big cities
            to continue their secular education, while protecting them from the
            hyper-politicized environment. To support these educational efforts, people
            who shared Fethullah Gülen’s service-ethic now set up a system of bursaries
            for students. The funding for the hostels and bursaries came entirely from
            local communities among whom Fethullah Gülen’s service-ethic idea (hizmet) was
            spreading steadily. With Fethullah Gülen’s encouragement, around his discourse
            of positive action and responsibility, ordinary people were starting to
            mobilize to counteract the effects of violent ideologies and of the ensuing
            social and political disorder on their own children and on youth in general.
            Students in the hostels also began to play a part in spreading the discourse
            of service and positive action. Periodically, they returned to their home
            towns and visited surrounding towns and villages, and, talking of their
            experiences and the ideas they had encountered, consciously diffused the
            hizmet idea in the region. Also, from 1966 onward, Fethullah Gülen’s talks and
            lectures had been recorded on audio cassettes and distributed throughout
            Turkey by third parties. Thus, through already existing networks of primary
            relations, this new type of community action, the students’ activities, and
            the new technology of communication, the hizmet discourse was becoming known
            nation-wide.
          </p>
          <p>
            In 1974, the first university preparatory courses were established in Manisa,
            where Fethullah Gülen was posted at the time. Until then, it was largely the
            children of very wealthy and privileged families who had access to university
            education. The new courses in Manisa offered the hope that in future there
            might be better opportunities for children from ordinary Anatolian families.
            The idea took hold that, if properly supported, the children of ordinary
            families could take up and succeed in higher education. As word spread of
            these achievements, Fethullah Gülen was invited, the following year, to speak
            at a series of lectures all over Turkey. The service idea became widely
            recognized and firmly rooted in various cities and regions of the country.
            From this time on, the country-wide mobilization of people drawn to support
            education and non-political altruistic services can be called a movement – the
            Gülen Movement.
          </p>
          <p>
            In 1976, the Religious Directorate posted Fethullah Gülen to Bornova, Izmir,
            the site of one of Turkey’s major universities with a correspondingly large
            student population and a great deal of the militant activism typical of
            universities in the 1970s. It came to his attention that leftist groups were
            running protection rackets to extort money from small businessmen and
            shopkeepers in the city and deliberately disrupting the business and social
            life of the community. The racketeers had already murdered a number of their
            victims. In his sermons, Fethullah Gülen spoke out and urged those being
            threatened by the rackets neither to yield to threats and violence, nor to
            react with violence and exacerbate the situation. He urged them, instead, to
            report the crimes to the police and have the racketeers dealt with through the
            proper channels. This message led to threats being made against his life. At
            the same time, he challenged the students of left and right to come to the
            mosque and discuss their ideas with him and offered to answer any questions,
            whether secular or religious, which they put to him. A great many students
            took up this offer. So, in addition to his daily duties giving traditional
            religious instruction and preaching, Fethullah Gülen devoted every Sunday
            evening to these discussion sessions.
          </p>
          <p>
            In 1977, he traveled in northern Europe, visiting and preaching among Turkish
            communities to raise their consciousness about values and education and to
            encourage them in the same hizmet ethic of positive action and altruistic
            service. He encouraged them both to preserve their cultural and religious
            values and to integrate into their host societies. Now thirty-six, Fethullah
            Gülen had become one of the three most widely recognized and influential
            preachers in Turkey. For example, on one occasion in 1977 when the prime
            minister, other ministers and state dignitaries came to a Friday prayer in the
            Blue Mosque in Istanbul, a politically sensitive occasion in Turkey, Fethullah
            Gülen was invited to preach to them and the rest of the congregation.
          </p>
          <p>
            Fethullah Gülen encouraged participants in the Movement to go into publishing.
            Some of his articles and lectures were published as anthologies and a group of
            teachers inspired by his ideas established the Teachers’ Foundation to support
            education and students.
          </p>
          <p>
            In 1979, this Foundation started to publish its own monthly journal, Sizinti,
            which became the highest selling monthly in Turkey. In terms of genre, it was
            a pioneering venture, being a magazine of sciences, humanities, faith, and
            literature. Its publishing mission was to show that science and religion were
            not incompatible and that knowledge of both was necessary to be successful in
            this life. Each month since the journal was founded, Fethullah Gülen has
            written for it an editorial and a section about the spiritual or inner aspects
            of Islam, that is, Sufism, and the meaning of faith in modern life.
          </p>
          <p>
            In February 1980, a series of Fethullah Gülen’s lectures, attended by
            thousands of people, in which he preached against violence, anarchy and
            terror, were made available on audiocassette.
          </p>
          <p>
            In 1980, on September 5, Fethullah Gülen spoke from the pulpit before taking
            leave of absence for the next twenty days because of illness. From March 20,
            1981, he took indefinite leave of absence. By the third coup, the Turkish
            public appeared to have learnt a lesson. There was no visible public reaction.
            The faith communities, including the Fethullah Gülen Movement, continued with
            their lawful and peaceful activities without drawing any extra attention to
            themselves. Fethullah Gülen and the Movement avoided large public gatherings
            but continued to promote the service-ethic through publishing and small
            meetings. At this point, the Movement turned again to the use of technology
            and for the first time in Turkey a preacher’s talks were recorded and
            distributed on videotape. Thus, in spite of the atmosphere of intimidation
            following the coup, the hizmet discourse, far from being suppressed, continued
            to spread in a way that, ironically, was possibly more effective. In the years
            immediately following the coup, the Movement continued to grow and act
            successfully. In 1982, Movement participants set up a private high school in
            Izmir, Yamanlar Koleji.
          </p>
          <p>
            In 1989, Fethullah Gülen was approached by the Directorate of Religious
            Affairs and requested to resume his duties. His license was reinstated to
            enable him to serve as an Emeritus Preacher with the right to preach in any
            mosque in Turkey. Between 1989 and 1991, he preached in Istanbul on Fridays
            and on alternate Sundays in Istanbul and Izmir in the largest mosques in the
            cities. His sermons drew crowds in the tens of thousands, numbers
            unprecedented in Turkish history. These sermons were videotaped and also
            broadcast. At the beginning of the 1990s, the police uncovered a number of
            conspiracies by marginal militant Islamists and other small ideological groups
            to assassinate Fethullah Gülen. These groups also placed agent-provocateurs in
            the areas around the mosques where he preached with the aim of fomenting
            disorder when the crowds were dispersing after Fethullah Gülen’s sermons. Due
            to Fethullah Gülen’s warnings and the already established peaceful practices
            of the Movement, these attempts failed and the agent- provocateurs were dealt
            with by the police.
          </p>
          <p>
            In 1991, Fethullah Gülen once again ceased preaching to large mosque
            congregations. He felt that some people were trying to manipulate or exploit
            his presence and the presence of Movement participants at these large public
            gatherings. However, he continued to be active in community life, in teaching
            small groups and taking part in the collective action of the Movement. In
            1992, he traveled to the United States, where he met Turkish academics and
            community leaders, as well as the leaders of other American faith communities.
            By this stage, the number of schools in Turkey established by the participants
            in the Gülen Movement had reached more than a hundred, not counting
            institutions such as study centers and university preparatory courses. From
            January 1990, Movement participants began to set up schools and universities
            in Central Asia too, often working under quite harsh conditions.
          </p>
          <p>
            Starting in 1994, Fethullah Gülen pioneered a rejuvenation of the Interfaith
            Dialog spirit in the Turkish-Muslim tradition, which was forgotten amidst the
            troublesome years of the early twentieth century. The Foundation of
            Journalists and Writers, of which Gulen was the honorary president, organized
            a series of gatherings involving leaders of religious minorities in Turkey
            such as the Greek Orthodox Patriarch, Armenian Orthodox Patriarch, Chief Rabbi
            of Turkey, Vatican’s Representative to Turkey and others. The “Abant”
            platform, named after the location of the first meeting in Bolu, Turkey,
            brought together leading intellectuals from all corners of the political
            spectrum, the leftists, the atheists, the nationalists, the religious
            conservatives, and the liberals, providing for the first time in recent
            Turkish history a place where such figures could debate freely about the
            common concerns of all citizens and pressing social problems.
          </p>
          <p>
            During this period Fethullah Gülen made himself increasingly available for
            comment and interview in the media and began to communicate more with state
            dignitaries in order to help ease the tensions generated by the artificial
            debates around a phantom threat to the secular nature of the Turkish republic.
            The showdown between the military wing of the National Security Council and
            the ruling Virtue Party-True Path Party coalition eventually led to the
            so-called “February 28, 1997 post-modern military coup,” which forced the
            coalition government to resign and a harsh set of social engineering measures
            to be pursued by the new government under close military scrutiny.
          </p>
          <p>
            In March 1999, upon the recommendation of his doctors, Fethullah Gulen moved
            to the U.S. to receive medical care for his cardiovascular condition. Upon
            recommendation of his doctors, Gulen stayed in the U.S. to continue to receive
            medical care and to avoid stress caused by politically charged atmosphere of
            the February 28 post-modern military coup.
          </p>
          <p>
            The growing influence of Fethullah Gulen and the significance of the civic
            movement he helped generate worried some circles in the country who benefited
            from a closed society with government-favored enterprises, a monopoly on the
            intellectual life and an isolationist approach to foreign affairs. These
            circles accused Gulen of having long-term political ambitions and eventually
            persuaded an ultra-nationalist prosecutor to bring charges against him in 2000
            based on a doctored set of video clips which first appeared in mass media in
            June 1999. While these charges were found to be baseless and eventually
            dismissed in 2008, the case caused a set-back in the interfaith and
            intercultural dialog spirit that Gulen helped re-kindle.
          </p>
          <p>
            He currently lives at a retreat facility in Pennsylvania together with a group
            of students, scholars and a few visitors who consider it a “good” day in terms
            of his health if he is able to have a half-hour conversation answering their
            questions.
          </p>
          <p>
            In 1999, Fethullah Gülen traveled to the United States to receive medical care
            for his cardiovascular condition. He underwent a heart operation in 2004,
            following which his doctors recommended he avoid stress. For this reason, he
            chose to live far away from the politically-charged atmosphere in Turkey, and
            was granted permanent residency by the U.S. government in 2006. He was among
            the first Muslim scholars to publicly condemn the September 11 attacks. His
            condemnation message appeared in The Washington Post on September 21, 2001.
          </p>
          <p>
            Gülen currently lives with a group of students and his doctors at a retreat
            facility in Pennsylvania, where he dedicates his time to reading, writing,
            teaching, individual and small group worship, and receiving a few visitors, as
            his health permits. He has been visited by American academics and religious
            leaders as well as members of the Turkish-American community. He maintains his
            ascetic and spiritual way of life, spending most of his time in his modest
            room. He remains concerned with happiness and alleviating the suffering of
            humans around the world. He also encourages people to be involved in
            organizing aid campaigns for those affected by natural disasters throughout
            the world, in places such as Indonesia, Myanmar, and Haiti. Most days he
            devotes a half an hour to conversation and answering the questions, and is
            sometimes consulted about service projects. His conversations are recorded and
            made available to the public on a website (<a
              href="http://www.herkul.org/"
              id="A_67"
              >www.herkul.org</a
            >) Throughout the years in Pennsylvania, he has been interviewed several times
            by local and international journalists.
          </p>

          <p id="reg">
            —————————————————–<br id="BR_69" />
            This brief biography is mainly based on Fethullah Gülen’s biographical
            interview, Küçük Dünyam (Istanbul: Ufuk, 2006), his latest publications, the
            series of Kırık Testi (7 volumes, Istanbul), the biographical analysis about
            Fethullah Gülen by Ali Ünal, Bir Portre Denemesi (Istanbul: Nil, 2002), and it
            includes excerpts from “Chapter 2: Historical Background” of the book entitled
            “The Gulen Movement: Civic Service without Borders” by Muhammed Cetin (New
            Jersey: Blue Dome, 2008).
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FethullahGulen",
};
</script>

<style>
.container__image {
  margin: 43px;
  float: left;
}
.container__text {
  display: inline;
}

.container__text > h4 {
  text-align: left;
}
</style>
