<template>
  <div class="iflc">
    <AboutIflc />
    <Videos />
  </div>
</template>

<script>
import AboutIflc from "../components/AboutIflc.vue";
import Videos from "../components/Videos.vue";
export default {
  name: "Iflc",
  components: { AboutIflc, Videos },
};
</script>

<style>
.ilfc {
  max-height: calc(100vh - (300px));
}
</style>
