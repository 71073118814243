<template>
  <div id="content">
    <Navbar />
    <div style="height: 100px"></div>
    <router-view class="asyl" />
    <Footer id="footer"/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
  name: "app",
  components: {
    Navbar,
    Footer
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html,body{height:100%;}
body{display:flex; flex-direction:column;}
#content{flex: 1 0 auto;}
#footer{flex-shrink:0;} */

.asyl {
  padding: 10px 0px;
  min-height: calc(100vh - (300px));
}

body {
  font-family: "Poppins", sans-serif;
}
header {
  width: 100vw;
  background-color: rgb(255, 255, 255);
  padding-top: 15px;
}
</style>
